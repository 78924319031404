import React from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

export const ItemCard = (props) => {
  return (
    <SCard>
      <Link to={props.href}>
        <div>
          <img src={props.img} alt="product" />
        </div>
        <p>{props.itemName}</p>
      </Link>
    </SCard>
  );
};

const SCard = styled.div`
  a {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: all 0.2s;
    div {
      width: 100%;
      aspect-ratio: 1/1;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s;
      }
    }
    p {
      font-size: 0.9rem;
      line-height: 1;
      font-weight: 700;
      margin-top: 1rem;
    }
    /* hover */
    @media screen and (min-width: 1025px) {
      &:hover {
        div {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
    /* ------------ */
    /* TB */
    @media screen and (max-width: 1023px) {
      &:active {
        div {
          img {
            transform: scale(1.2);
          }
        }
      }
    }
  }
`;
