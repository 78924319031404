import React from "react";
import { Link } from "gatsby";

import { Breadcrumbs } from "../../components/breadcrumbs/breadcrumbs";
import { Layout } from "../../components/layout/layout";
import { ProductItemlistSection } from "../../components/section/productItemlistSection";
import { ProductCategorySection } from "../../components/section/productCategorySection";

const CategoryPage = () => {
  return (
    <Layout
      title="カテゴリ | LIME"
      jpUrl="/product/category/"
      enUrl="/en/product/category/"
    >
      <Breadcrumbs>
        <Link to="/">ホーム</Link>
        <span>/</span>
        <Link to="/product/">製品案内</Link>
        <span>/</span>
        <Link to="/product/category/">カテゴリ</Link>
        <span>/</span>
      </Breadcrumbs>
      <ProductItemlistSection />
      <ProductCategorySection visible="show" />
    </Layout>
  );
};

export default CategoryPage;
