import ImgProductItem001 from "../images/product_item_001.jpg";
import ImgProductItem002 from "../images/product_item_002.jpg";
import ImgProductItem003 from "../images/product_item_003.jpg";
import ImgProductItem004 from "../images/product_item_004.jpg";
import ImgProductItem005 from "../images/product_item_005.jpg";
import ImgProductItem006 from "../images/product_item_006.jpg";

export const ProductItemList = [
  {
    href: "/product/category/item001/",
    itemName: "ITEM001",
    img: ImgProductItem001,
  },
  {
    href: "/product/category/item001/",
    itemName: "ITEM002",
    img: ImgProductItem002,
  },
  {
    href: "/product/category/item001/",
    itemName: "ITEM003",
    img: ImgProductItem003,
  },
  {
    href: "/product/category/item001/",
    itemName: "ITEM004",
    img: ImgProductItem004,
  },
  {
    href: "/product/category/item001/",
    itemName: "ITEM005",
    img: ImgProductItem005,
  },
  {
    href: "/product/category/item001/",
    itemName: "ITEM006",
    img: ImgProductItem006,
  },
];
