import React from "react";
import styled from "@emotion/styled";

import { ContainerWrapper } from "../wrapper/containerWrapper";
import { SectionHeading } from "../heading/sectionHeading";
import { ProductItemList } from "../../utils/productItemList";
import { ItemCard } from "../card/itemCard";

export const ProductItemlistSection = () => {
  return (
    <SSection>
      <ContainerWrapper>
        <SHeading>
          <SectionHeading jp="製品一覧" en="Item" position="left" />
        </SHeading>
        <SGrid>
          {ProductItemList.map(({ href, itemName, img }) => {
            return (
              <ItemCard
                href={href}
                itemName={itemName}
                img={img}
                key={itemName}
              />
            );
          })}
        </SGrid>
      </ContainerWrapper>
    </SSection>
  );
};

const SSection = styled.section`
  padding: 4rem 0;
  /* ---------- */
  /* TB */
  @media screen and (max-width: 1023px) {
    padding: 2rem 0;
    width: 100%;
    .container {
      flex-direction: column;
    }
  }
  /* ---------- */
  /* TB */
  @media screen and (max-width: 599px) {
  }
`;

const SHeading = styled.div`
  text-align: left;
`;

const SGrid = styled.div`
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(min(220px, 100%), 1fr));
  gap: 32px 16px;
`;
